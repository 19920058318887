import { bindable, inject }        from 'aurelia-framework';
import { BaseListViewModel }       from 'base-list-view-model';
import { FilterFormSchema }        from 'modules/management/concrete/manage-studies/filter-form-schema';
import { AppContainer }            from 'resources/services/app-container';
import { ManageStudiesRepository } from './services/repository';

@inject(AppContainer, ManageStudiesRepository, FilterFormSchema)
export class ListManageStudies extends BaseListViewModel {

    listingId = 'management-manage-studies-listing';

    @bindable headerTitle    = 'listing.management.concrete.manage-studies';
    @bindable newRecordRoute = 'management.concrete.manage-studies.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filterFormSchema
     */
    constructor(appContainer, repository, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.concrete.manage-studies.manage',
            'management.concrete.manage-studies.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      this.repository,
            show:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.concrete.manage-studies.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.concrete.manage-studies.manage', 'management.concrete.manage-studies.view']),
            },
            edit:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.concrete.manage-studies.edit', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.concrete.manage-studies.manage', 'management.concrete.manage-studies.edit']),
            },
            destroy:         {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.concrete.manage-studies.manage', 'management.concrete.manage-studies.delete']),
            },
            options:         [],
            selectable:      true,
            destroySelected: true,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'code',
                    name:  'manage_studies.code',
                    title: 'form.field.code',
                },
                {
                    data:  'specification',
                    name:  'manage_studies.specification',
                    title: 'form.field.specification-book',
                },
                {
                    data:       'manage_fields',
                    name:       'manage_fields',
                    title:      'form.field.fields',
                    type:       'processed',
                    processor:  (row) => `<ul>` + row.manage_fields.map((name) => `<li>${name}</li>`).join('') + `</ul>`,
                    searchable: false,
                    orderable:  false,
                },
                {
                    data:    'observations',
                    name:    'manage_studies.observations',
                    title:   'form.field.observations',
                    display: false,
                },
                {
                    data:  'created_by',
                    name:  'users.name',
                    title: 'form.field.created-by',
                },
                {
                    data:            'created_at',
                    name:            'manage_studies.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

}
